/* Header CSSS */
.header {
  text-decoration: none;
  background: #00ff26;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #00aeff, #77c48a);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #00aeff, #77c48a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.mdl-layout__drawer-button {
  background: none !important;
}

.nav-item {
  size: 20px;
  color: black !important;
}

.nav-item:hover {
  color: white !important;
  background-color:rgb(41, 39, 39);
}

/* Landing Page CSS */
.landing-content {
  margin: auto;
  overflow: auto;
  position: fixed;
  width: 100%;
  /* height: 100%; */
}

.landing-grid {
  margin: auto;
  overflow: auto;  margin: auto;
  overflow: auto;
  background: rgb(41, 39, 39);
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  /* margin-bottom: 140px; */
  /* padding-top: 5em; */
}

.avatar-img {
  width: 100%;
  /* border-radius: 50%;
  height: 30%;
  margin: 3em;
  text-align: center;
  margin-left: auto;
  margin-right: auto; */
  /* border: solid rgb(41, 39, 39); */
}

.banner-text {
  background-color: black;
  opacity: .8;
  width: 100%;
  margin: auto;
  border-radius: 10px;

  /* margin-bottom: 2em; */
}

.banner-text h1 {
  font-size: 30px;
  /* font-weight: bold; */
  color: white;
  text-align: center;
  padding-top: 0.5em;
  margin-bottom: 0px;
}

.banner-text h2 {
  color: white;
  text-align: center;
  font-size: 16px;
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  padding: 1vw;
  font-size: 12px;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 1vw;
}

.social-links a {
  color: white;
  font-size: 5vw;
  padding-bottom: 2vw;
}

/* Projects Page CSS */
.content {
  background: rgb(41, 39, 39);
  position: fixed;
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
}

.flex-box {
  width: 95%;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3em;
  
}

/* About Page CSS */
.about-body {
  /* margin: auto; */
  overflow: auto;
  background: rgb(41, 39, 39);
  position: fixed;
  width: 100%;
  height: calc(100% - 112px);
  justify-content: center;
  padding-top: 56px;
}

.about-grid {
  width: 100%;
  /* height: calc(100% - 56px); */
  justify-content: center;
}

.about-hr {
  border-top: 3px solid #00aeff;
  width: 50%;
}

.about-left-col {
  background: white;
  border-radius: 10px;
  padding: 1em;
}

.about-list i {
  font-size: 3rem;
  padding-right: 1rem;
  color: #00aeff;
  
}

.about-list li {
  padding: 2px;
  text-overflow: ellipsis;
}

.about-right-col {
  font-size: 20px;
  background: white;
  border-radius: 10px;
  padding: 1em;
}

.about-right-col p {
  color: grey;
}

.about-avatar-img {
  border-radius: 50%;
  height: 16em;
}

/* Media Queries */

@media (max-width: 768px) {
  .horizontal-nav{
    display: none !important;
  }
}

@media (min-width: 769px) {
    .mdl-layout__drawer-button {
        display: none !important;
    }
    
    .banner-text{
      width: 40%;
      position: fixed;
      top: 140px;
      left: 40px;
    }

    .banner-text h1 {
      font-size: 36px;
    }
    
    .banner-text h2 {
      font-size: 24px;
    }
    
    .banner-text p {
      padding: 1vw;
      font-size: 18px;

    }


    .about-grid {
      width: 80%;
    }
}

.mdl-grid {
  padding: 0px !important;
}
